@tailwind base;
@tailwind components;
@tailwind utilities;


body{ 
  font-family: Lato!important;
  @apply text-blueDark m-0 bg-white
}

h1,h2,h3,h4,h5,h6{
  @apply text-blueDark 
}

/*
.bg-blueLight.welcome h1,
.bg-blueLight h2,
.bg-blueLight h3,
.bg-blueLight h4,
.bg-blueLight h5,
.bg-blueLight h6{
  @apply text-white 
}
*/

h1{ 
 @apply text-lg m:text-xl l:text-2xl xl:text-3xl xxl:text-5xl font-semibold
}

h1.normal{ 
  @apply font-normal 
 }

h2{ 
  @apply  text-xl xxl:text-4xl
 }

h3{
  @apply text-base m:text-lbase xxl:text-xl
}

div.Content > div,
section.Header > div,
section.welcome > div,
section.Content > div{
  max-width: 1540px;
  @apply m-auto pl-4 pr-4  xxl:pl-0 xxl:pr-0
}
section.Footer > div{
  max-width: 1540px;
  @apply m-auto pl-4 pr-4 xxl:pl-0 xxl:pr-0
}

section{
  @apply bg-white
}

.link{ cursor: pointer;}

.button{
  @apply text-white bg-blueDark rounded-sm pt-1.5 pb-1.5 pl-6 pr-6 justify-between text-base inline-block
}

.button.disabled{
  @apply text-blueDark bg-disabledButton
}

.ellipsis {
  position: relative;
}

.ellipsis:before {
  content: '&nbsp;';
  visibility: hidden;
}

.ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}